.empty{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  padding-left: 20px;
}

.filterActive {
  display: flex;
  justify-content: center;
  background-color: #E0E0E0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.customerList {
  margin-top: -60px;
  position: relative;
  z-index: 0;
}