.shipment-bar-title {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  color: #020202;
}
.shipment-bar-title > p:nth-child(2) {
  color: rgba(0, 0, 0, .4);
}

.shipment-progress-bar {
  height: 6px;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  border-radius: 50px;
  z-index: 2;
  overflow: hidden;
}

.scanned-item-bar {
  position: absolute;
  height: 100%;
  border-radius: 50px 0 0 50px;
  background-color: rgba(38, 137, 89, 1);
  z-index: 5;
}
.missing-item-bar {
  width: 0;
  position: absolute;
  height: 100%;
  border-radius: 50px 0 0 50px;
  background-color: rgba(255, 5, 5, 1);
  z-index: 4;
}
