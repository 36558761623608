@import url("https://fonts.googleapis.com/css2?family=Bevan&family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #f7f8fa;
  --secondary-color: #ffffff;
  --accent-color: #252450;
  --green: #48bb78;
  --yellow: #e3b309;
  --light-green: #48bb78;
  --light-yellow: #e3b309;
  --red: #ef5353;
  --light-gray: #eaeff5;
  --light-accent: #25244e;
  --gray: #333333;
  --header-padding: 20px;
  --lr-padding-around: 30px;
  --header-height: 80px;
  --form-around-padding: 30px;
  --form-around-radius: 5px;
  --border-color: #666666;
  --purple-color: #262889;
  --light-gray-color: #BBBBBB;
  --jet-black-color: #1D1C1C;
}

body {
  font-family: "Open Sans", "Poppins";
  font-size: 15px;
  color: var(--accent-color);
}

.padding-0 {
  padding: 0 !important;
}

.me-5 {
  margin-right: 10px;
}

h1 {
  font-size: 23px;
  color: var(--accent-color);
}

h2 {
  font-size: 15px;
  color: var(--accent-color);
}

h3 {
  font-size: 13px;
  color: var(--accent-color);
}

a {
  color: var(--accent-color);
  text-decoration: underline;
}

a:hover {
  color: var(--accent-color);
  opacity: 80%;
}

.ant-btn-lg {
  border-radius: 5px;
}

.action-link {
  color: var(--accent-color) !important;
  text-decoration: underline !important;
}

.no-underline {
  text-decoration: none !important;
}

.underline {
  text-decoration: underline;
}

.overlay {
  background: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99;
}

.ant-btn-ghost,
.ant-btn-ghost:hover {
  border-color: var(--accent-color);
  color: var(--accent-color);
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: var(--purple-color);
  border-radius: 8px;
  font-weight: 400;
  color: #fff;
  height: 42px;
  font-size: 15px;
  border-color: var(--purple-color);
}

.ant-btn-default,
.ant-btn-default:hover,
.ant-btn-default:focus {
  background-color: #fff;
  border-radius: 8px;
  color: var(--gray);
  height: 42px;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid var(--gray);
}

.link-button {
  color: var(--accent-color);
  font-size: 14px;
  opacity: 1;
}

.link-button:hover {
  opacity: 0.9;
}

.ant-layout-header {
  height: 95px;
}

.ant-tabs-tab {
  font-size: 16px;
  color: #1D1C1C !important;
  padding: 6px 0 !important;
}

.tab-content-pad {
  margin: 0 32px;
}

.ant-tabs-tab {
  font-family: 'Open Sans';
  font-weight: 400;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  text-shadow: none !important;
  color: var(--purple-color) !important;
}

.ant-tabs-tab:hover {
  color: var(--purple-color) !important;
}

.ant-tabs-ink-bar {
  background: var(--purple-color);
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
  height: 3px !important;
  border-radius: 7px !important;
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin-bottom: 12px !important;
  margin: 0 -30px;
  padding: 0 30px;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  gap: 20px;
}

.main-header {
  border-bottom: 1px solid var(--light-gray);
  border-color: var(--light-gray);
  padding-left: var(--lr-padding-around);
  padding-right: var(--lr-padding-around);
}

.main-header .ant-menu-horizontal {
  border-bottom: 1px solid var(--light-gray);
}

.ant-menu-submenu-open {
  color: transparent !important;
}

.logo {
  height: var(--header-height);
  border-bottom: 1px solid;
  border-color: var(--border-color);
  margin-bottom: 15px;
  padding: var(--header-padding);
}

.site-layout-sub-header-background {
  background: #f7f8fa;
}

.site-layout-background {
  display: flex;
  /* align-items: center; */
  background: #ffffff;
  border-bottom: 1px solid #D9D9D9;
  padding: 9px 12px 9px 36px;
  height: 95px;
}

.site-layout-background .header-text {
  margin: 0;
}


.ant-menu {
  background: #ffff;
}

.ant-layout-sider {
  background-color: var(--accent-color);
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #080808;
  color: #ffff !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  color: #ffff !important;
}

.nested-sider .ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline {
  color: #000000 !important;
  font-size: 16px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #080808;
  border-color: #fff;
  border-right: 4px solid;
  font-weight: 500;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
  color: #ffffff;
  font-weight: 400 !important;
  font-size: 16px;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: black !important;
  border-right: unset !important;
}

.nested-sider .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #ffff !important;
  z-index: 100;
  border-radius: 7px;
  color: var(--purple-color) !important;
  font-size: 16px;
  /* padding: 0 12px !important; */
  font-weight: 700 !important
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--purple-color) !important;
  z-index: 100;
  border-radius: 7px;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected>span>a {
  font-weight: 600 !important;
}

/* Accordion CSS*/

.ant-collapse {
  border: none;
  margin-top: 20px;
}

.ant-collapse-item {
  margin-bottom: 20px;
}

.ant-collapse-header {
  background-color: #fff;
  border: 1px solid var(--light-gray);
  color: var(--accent-color);
  font-weight: 500;
  font-size: 16px;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none;
}

.ant-collapse-content {
  border: none;
}

/*Table Css*/

.ant-table {
  border-radius: 15px;
}

.ant-table-thead>tr>th {
  background: var(--light-gray);
}

.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 15px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 15px;
}

.ant-spin-nested-loading {
  margin-top: 10px;
}

/* Content Wrapper*/

.content-wrapper {
  padding: var(--lr-padding-around);
}

/* Form Css*/

.form-data-filter .ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.form-data-filter .ant-form-vertical .ant-form-item-label>label {
  font-weight: 500;
}

.form-data .ant-form-vertical .ant-form-item-label>label {
  font-weight: 500;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 42px;
}

.form-data .ant-input {
  padding: 8px 15px 8px 15px;
  border-radius: 8px;
  border: 1px solid var(--light-gray-color);
  font-size: 15px;
  line-height: 20.5px;
  height: 42px;
}

.form-data .ant-input-number {
  padding: 4px;
  border-radius: 8px;
  height: 42px;
  border: 1px solid var(--light-gray-color);
  width: 100%;
}

.form-data .ant-input[disabled] {
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.form-data {
  background-color: #fff;
  padding: var(--form-around-padding);
  border-radius: var(--form-around-radius);
}

/* .form-data
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.form-data .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: auto;
  padding: 6px 11px;
  border-radius: 5px;
} */


.ant-checkbox-wrapper {
  line-height: 27px;
}

.ant-input-group-addon {
  height: auto;
  border-radius: 5px;
}

.form-data .ant-form-item-label>label {
  color: var(--jet-black-color);
  font-weight: 600;
  font-size: 15px;
}

.form-data .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  color: var(--jet-black-color) !important;
}

.form-data .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.form-data .ant-checkbox:hover .ant-checkbox-inner,
.form-data .ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: var(--jet-black-color);
  border-radius: 4px !important;
}

.form-data .ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 4px !important;
  border-color: var(--jet-black-color);
}

.form-data .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--jet-black-color);
  border-color: var(--jet-black-color);
}

.form-data .ant-checkbox-checked:hover .ant-checkbox-inner {
  border-radius: 4px;
  border-color: var(--jet-black-color);
}

.form-data .ant-checkbox-checked::after {
  border-radius: 4px;
  border-color: var(--jet-black-color);
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #ffffff;
}

.form-data .ant-list-split .ant-list-item {
  border-bottom: none;
}

.form-data .ant-input-status-error {
  border: 1px solid var(--red) !important;
}

/*ToolTip*/

.ant-tooltip {
  max-width: 40%;
}

.ant-tooltip-inner {
  background-color: #fff;
  color: var(--accent-color);
}

.ant-tooltip-arrow-content::before {
  background-color: #fff;
}

/*List */

.ant-list-item-meta-description {
  color: var(--accent-color);
}

/* icon */

/* Card */

.form-card .ant-form {
  padding: 0;
}

/* Tags*/

.ant-tag {
  background-color: #fff;
  padding: 2px 0px;
  border-radius: 20px;
  min-width: 40px;
  text-transform: capitalize;
  text-align: center;
  font-size: 12px;
}

/* Notification*/
.ant-notification-notice-message {
  min-height: 42px;
}

/* Tab */

.uploader-wrapper .ant-upload-list-picture-card-container {
  width: 487px !important;
  height: auto !important;
}

.menu-wrapper-inner {
  position: relative;
}

/* Simple Accordion*/

.simple-accordion {
  cursor: pointer;

  width: 100%;

  text-align: left;
  outline: none;

  transition: 0.4s;
  position: relative;
  padding: 12px 16px;
}

.order-content-wrapper .simple-accordion.active {
  color: #000;
}

.simple-accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  font-size: 31px;
  line-height: 25px;
}

.simple-accordion.active:after {
  content: "\2212";
}

.ant-checkbox-checked.simple-accordion::after {
  position: relative !important;
  top: initial !important;
  left: initial !important;
  width: initial !important;
  height: initial !important;
  border: none !important;
  border-radius: unset !important;
  visibility: visible !important;
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.inner-table .ant-table-thead>tr>th {
  background: #898d93;
  color: #fff;
}

.inner-table .ant-table-tbody>tr.ant-table-row-selected>td,
.inner-table .ant-table-tbody>tr>td {
  background: #8080804f;
}

.work-schedule-wrapper .ant-card-body {
  padding: 10px;
}

.ant-body .ant-card-body {
  padding: 0px !important;
}

.form-card .ant-card-body {
  padding: 0px !important;
}

.work-schedule-wrapper .work-list {
  text-align: left;
  padding-left: 20px;
}

.work-schedule-wrapper .border-success {
  text-align: center;
  border-top: 10px solid #01a001;
  border-radius: 10px;
}

.work-schedule-wrapper .border-warning {
  text-align: center;
  border-top: 10px solid #eeaf01;
  border-radius: 10px;
}

.work-schedule-wrapper .work-list-no-dot {
  list-style: none;
  text-align: left;
  padding-left: 0px;
}

.ant-popover-inner-content {
  padding: 5px;
}

.work-schedule-wrapper .ant-menu {
  background: none;
}

.ant-table-expanded-row .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 0px;
}

.ant-table-expanded-row .ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 0px;
}

/* .ant-table-expanded-row table {
  margin-bottom: 35px;
} */

.heading {
  font-weight: 600;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.text-right {
  text-align: right;
}

/* Absolute Center Spinner */
.full-loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.full-loading :before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
      rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.full-loading :not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  position: absolute;
  border: 0;
  top: 50%;
  left: 50%;
}

.full-loading :not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* .btm.ant-menu-root.ant-menu-inline.ant-menu-dark {
  padding-bottom: 35px;
} */

.inline-form-wrapper .ant-form-item-label {
  display: block;
  width: 100%;
  text-align: left;
}

.bulk-tabs .ant-tabs-tab {
  padding: 0px 0;
}

.down-icon,
.up-icon {
  display: none;
}

.down-icon {
  display: none;
}

.active .down-icon {
  display: block;
}

.active .up-icon {
  display: none;
}

.panel .ant-table {
  border-radius: 0px;
}

.panel .ant-table-thead>tr>th {
  background: #898d9345;
  color: #000;
}

.ant-collapse-header.active {
  border-bottom: 0px;
}

.panel .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 0px;
}

.panel .ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 0px;
}

.simple-accordion .ant-spin-nested-loading {
  margin-top: 10px;
}

.panel .ant-table-tbody>tr>td {
  background: transparent;
}

.panel .ant-table-tbody>tr.ant-table-row-selected>td,
.inner-table .ant-table-tbody>tr>td {
  background-color: transparent;
}

.scan-btn,
.scan-btn:hover,
.scan-btn:focus {
  padding: 2px;
  font-size: 13px;
  height: 27px;
}

.scans-wrapper .ant-list-lg .ant-list-item {
  padding: 10px 12px;
}

.scans-wrapper .ant-card-head {
  padding: 0px 10px;
}

.client-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--accent-color);
  color: #fff;
}

.client-wrapper .ant-select-arrow {
  color: #fff;
}

.overlay-wrapper {
  height: auto;
  position: relative;
}

.btm-menu {
  padding-bottom: 90px;
}

.work-list li,
.work-list-no-dot li {
  font-size: 12px;
}

.work-schedule-wrapper * {
  font-size: 12px !important;
}

.client-list-wrapper .ant-menu-submenu-title {
  padding-left: 0 !important;
}

.client-list-wrapper .ant-menu-item-only-child {
  padding-left: 5px !important;
}

.ant-card.criclebox {
  box-shadow: 0px 0px 9px #ccc;
  border-radius: 5px;
  min-height: 140px;
  cursor: pointer;
}

.ant-card.criclebox .ant-card-body {
  padding: 15px;
}

.ant-card.criclebox .number {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.ant-card.criclebox .ant-card-body .number2 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  line-height: 60px;
}

.cards .ant-card-body {
  padding: 15px;
}

.left-rail .ant-menu-vertical>.ant-menu-item,
.left-rail .ant-menu-vertical-left>.ant-menu-item,
.left-rail .ant-menu-vertical-right>.ant-menu-item,
.left-rail .ant-menu-inline>.ant-menu-item,
.left-rail .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.left-rail .ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.left-rail .ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.left-rail .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.left-rail .ant-menu-sub.ant-menu-inline>.ant-menu-item,
.left-rail .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 35px;
}

.ant-table-thead .ant-table-column-has-sorters {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.route-card-title {
  font-weight: 600;
  color: #01a001;
  font-size: 14px;
}

.text-left {
  text-align: left;
}

.on-mouse-over .on-mouse-over-action {
  display: none;
}

.on-mouse-over:hover .on-mouse-over-action {
  display: block;
}

.match-shipment-doc .ant-tag {
  border-radius: 0;
  padding: 0 10px;
  margin: 10px;
}

.site-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1890FF;
}

.site-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0;
}


/* CustomTable.css */

.redesign-table .ant-table {
  border: 1px solid var(--light-gray-color);
  overflow: hidden;
  border-radius: 7px !important;
}

.redesign-table td.ant-table-column-sort {
  background: #ffffff;
}

.redesign-table .ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 0px;
}

.redesign-table .ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 0px;
}

.redesign-table .ant-table-thead>tr>th {
  background: rgba(0, 0, 0, 0.1);
}

.redesign-table .ant-table-thead>tr {
  height: 45px !important;
}

.redesign-table .ant-table-cell::before {
  width: 0 !important;
}

.redesign-table .ant-table-thead>tr>.ant-table-cell {
  font-size: 15px;
  font-weight: 600 !important;
  color: var(--jet-black-color);
  border-bottom: 1px solid var(--light-gray-color);
}

.redesign-table .ant-table-container table>thead>tr:first-child th:first-child span.ant-checkbox {
  display: none;
}

.ant-table-thead>tr:nth-child(1) .ant-checkbox-wrapper {
  display: none;
}

.redesign-table .ant-table-tbody>tr>.ant-table-cell {
  font-size: 15px;
  font-weight: 400px;
  color: var(--gray);
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
}

.redesign-table .ant-table-cell {
  padding: 10px !important;
}

.redesign-table.extra-padding-first-col .ant-table-thead>tr> :first-child {
  padding-left: 72px !important;
}

/* td.ant-table-column-sort {
  background-color: transparent !important;
} */

.redesign-table .ant-pagination-item-link {
  background: #E0E0E0;
  border-radius: 4px;
}

.redesign-table .ant-pagination-item {
  background: #E0E0E0;
  border-radius: 4px;
}

.redesign-table .ant-pagination-item:hover {
  border: 1px solid var(--purple-color) !important;
}

.redesign-table .ant-pagination-item>a:hover {
  color: var(--purple-color) 80%;
}

.redesign-table .ant-pagination-next>button:hover {
  border: 1px solid var(--purple-color) !important;
}

.redesign-table .ant-pagination-next>button:hover {
  color: var(--purple-color) 80%;
}

.redesign-table .ant-pagination-prev>button:hover {
  border: 1px solid var(--purple-color) !important;
}

.redesign-table .ant-pagination-prev>button:hover {
  color: var(--purple-color);
}

.ant-modal-wrap {
  background-color: var(--bg, rgba(37, 30, 88, 0.87)) !important;
}

.redesign-table .ant-pagination-item-active {
  background: var(--purple-color);
  border-radius: 4px;
}

.redesign-table .ant-pagination-item-active>a:hover {
  color: white !important;
}

.redesign-table .ant-pagination-item-active>a {
  color: white;
}

.redesign-table .ant-pagination-item>a {
  text-decoration: none;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  letter-spacing: -0.01em;
}

.dropdown-with-name-wrapper .ant-select .ant-select-selector {
  border: 1px solid var(--light-gray-color) !important;
  border-radius: 8px !important;
}

.dropdown-with-name-wrapper .ant-select-selection-item {
  display: flex;
}

.form-data .ant-select .ant-select-selector {
  border: 1px solid var(--light-gray-color) !important;
  border-radius: 8px !important;
  height: 42px;
  align-items: center;
}

.dropdown-with-name-wrapper .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
  border: 1px solid var(--light-gray-color) !important;
}

.dropdown-with-name-wrapper .ant-select-selector:hover {
  border: 1px solid var(--light-gray-color) !important;
}

.dropdown-with-name-wrapper .ant-select-arrow .anticon {
  display: flex !important;
}

.dropdown-with-name-wrapper .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.ant-input {
  padding: 8px 15px 8px 15px;
  border-radius: 8px;
  border: 1px solid var(--light-gray-color);
  font-size: 15px;
  line-height: 20.5px;
  height: 42px;
}

.ant-input-affix-wrapper {
  border-radius: 8px;
  border: 1px solid var(--light-gray-color);
  font-size: 15px;
  padding: 0 10px;
}

.ant-modal-content {
  border-radius: 7px;
  background: var(--ffffff, #FFF);
  box-shadow: 0px 0px 35px 10px rgba(115, 115, 115, 0.20) !important;
}

.ant-modal-header {
  border-radius: 7px 7px 0 0;
  background-color: var(--jet-black-color);
}

.ant-modal-title {
  color: #ffff;
  font-size: 16px;
  font-weight: 600;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-upload.ant-upload-drag {
  width: 487px;
  height: 487px;
  border-radius: 50%;
  border: none;
  background-color: #E8E8E8;
}

.ant-upload.ant-upload-drag p {
  font-size: 15px;
  text-align: center;
  color: var(--gray);
  position: absolute;
  left: 180px;
  top: 375px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.site-content {
  height: 100vh;
  overflow: auto;
  width: calc(100vw - 280px);
  overflow-x: hidden;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 16px !important;
}

.ant-modal-close {
  color: #ffff;
}

.ant-modal-close:hover {
  color: #ffff;
  opacity: 0.8;
}

.log-out {
  position: fixed;
  bottom: 0;
  z-index: 100;
  height: 89px;
  text-align: center;
  transition: all 0.2s;
  background-color: #080808;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nested-bottom {
  position: fixed;
  border-top: 1px solid #BBBBBB;
  bottom: 0;
  z-index: 100;
  height: 89px;
  text-align: center;
  transition: all 0.2s;
  background-color: #E0E0E0;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.nested-bottom p {
  margin-bottom: 0;
  font-size: 16px;
  color: #1D1C1C;
  text-decoration: none;
}

.nested-bottom a {
  text-decoration: none;
}

.ant-layout-sider-children .ant-menu-item .anticon svg {
  fill: #fff;
}

.ant-menu-title-content a {
  text-decoration: none;
}

.ant-layout-sider .ant-divider-horizontal {
  margin: 15px 0;
}

.ant-divider {
  color: rgba(255, 255, 255, 0.65);
  border-top: 1px solid var(--border-color) !important;
}

.client-wrapper {
  padding: 14px;
}

.side-bar {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nested-sidebar {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.side-bar::-webkit-scrollbar {
  display: none;
}

.nested-sidebar::-webkit-scrollbar {
  display: none;
}

.customer-list::-webkit-scrollbar {
  width: 20px;
  padding: 10px;
}

.customer-list::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: #919191;
  border: 7px solid #E0E0E0;
  background-clip: padding-box;
}

.customer-list::-webkit-scrollbar-track {
  cursor: pointer;
  width: 50px;
  margin-bottom: 100px;
}

.customer-list {
  background-color: #E0E0E0;
  height: calc(100vh - 89px);
  overflow-y: scroll;
}

.side-bar .sider {
  min-height: 100vh;
  background: #080808 !important;
}

.side-bar .nested-sider {
  min-height: 100vh;
  background: #E0E0E0 !important;
}

.user-name {
  color: #fff;
  margin: 2px 0;
  font-weight: bold;
  font-size: 16px;
}

.sign-out-text {
  color: #fff;
  margin: 2px 0;
  font-size: 16px;
}

.sign-out-text:hover {
  color: #fff;
}

.nav-bar {
  margin-top: 60px;
  padding: 0 27px;
}

.nested-nav-bar {
  margin-top: 100px;
  padding: 0 0 80px 17px;
  min-height: 100vh;
  margin-bottom: 70px;
}

.user-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 27px;
  height: 95px;
}

.search-bar {
  display: flex;
  padding: 50px 10px 0 10px;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid #D9D9D9;
  background-color: #E0E0E0;
  z-index: 1;
}

.pl-4 {
  padding-left: 16px;
}

.search-bar-input {
  border-bottom: 3px solid #919191 !important;
  border-radius: 0;
  border: none;
  background: #E0E0E0;
  outline: none;
  z-index: 1;
}

.search-bar .ant-input-affix-wrapper>input.ant-input::placeholder {
  color: var(--gray);
}

.search-bar .ant-input-affix-wrapper>input.ant-input {
  background-color: #E0E0E0;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: calc(100vw - 560px);
  margin: 0 -30px;
  padding: 0 30px;
  padding: 0;
  padding: 23px;
  z-index: 100;
  background-color: #ffff;
  border-top: 1px solid #D9D9D9;
}

.main-sticky-footer {
  position: fixed;
  bottom: 0;
  width: calc(100vw - 280px);
  margin: 0 -30px;
  padding: 0 30px;
  padding: 0;
  padding: 23px;
  z-index: 100;
  background-color: #ffff;
  border-top: 1px solid #D9D9D9;
}

.mb-14 {
  margin-bottom: 69px;
}

.ml-2 {
  margin-left: 8px;
}

.link-text {
  text-decoration: none;
  font-size: 15px;
}

.-mb-5 {
  margin-bottom: -18px;
}

.font-600 {
  font-weight: 600;
}

.text-16 {
  font-size: 16px;
}

.pt-7 {
  padding-top: 28px;
}

.flex {
  display: flex;
}

.p-6 {
  padding: 24px;
}

.p-2 {
  padding: 8px;
}

.sku-btn {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

/* Hide the table buttons by default */
.edit-btn,
.delete-btn {
  visibility: hidden;
  border: none;
  cursor: pointer;
  background: none;
}

/* Show the table buttons on hover */
tr:hover .edit-btn,
tr:hover .delete-btn {
  visibility: visible;
  cursor: pointer;
}

.p-0 {
  padding: 0;
}

.justify-center {
  display: flex;
  align-items: center
}

.px-4 {
  padding: 0 16px 0 24px;
}

.custom-tooltip .ant-tooltip-inner {
  min-width: 250px;
  max-width: 400px;
  border-radius: 7px;
  transform: translateX(-100px);
  box-shadow: 0px 0px 4px 0px rgba(68, 66, 66, 0.41);
  Padding: 0 5px !important;
}

.mb-25 {
  margin-bottom: 100px;
}

.sku-input {
  min-width: 115px;
  height: 42px;
  border-radius: 7px;
  background: #E0E0E0;
  border: 1px solid #E0E0E0;
  padding-left: 19px;
  padding-top: 10px;
  white-space: nowrap;
  font-size: 15px;
  color: #343434;
  margin: 17px 17px 0 0;
  transition: border-color 0.4s ease-out, color 0.4s ease-out;
}

.sku-wrapper {
  position: relative;
  cursor: pointer;
}

.sku-action {
  opacity: 0;
  transition: 0.4s ease-out;
}

.sku-wrapper:hover {
  .sku-action {
    opacity: 1;
  }

  .sku-input {
    border: 1px solid #262889;
    color: #262889;
  }
}

.sku-action .action-btn {
  margin: 0;
  font-size: 32px;
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.sku-setting-wrapper {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.sku-label {
  padding-left: 5px;
  padding-top: 24px;
  margin-bottom: -8px;
}

.modal-footer-btn {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 40px;
}

.color-jet-blk {
  color: var(--jet-black-color);
}

.inner-header {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 59px;
}

.-mb-18 {
  margin-bottom: -70px;
}

.ant-picker {
  border-radius: 8px;
  width: 100%;
  border-color: var(--light-gray-color);
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  color: var(--jet-black-color);
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  color: #ffffff;
}

.ant-picker-panel-container {
  border-radius: 16px;
  background: #000000 2.25%;
}

.ant-picker-content th {
  color: #797B86;
  font-weight: bold;
}

.ant-picker-cell-in-view {
  color: #ABB0BD !important;
}

.ant-picker-cell {
  color: #45454A;
}

.ant-picker-cell:hover {
  color: black;
}

.ant-picker-cell-in-view:hover {
  font-weight: 600;
  color: black !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: black;
  color: #F1F4F8;
  font-weight: 600;
}

.ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-today {
  border: #000000 2.25%;
}

.ant-picker-header {
  border: none;
  color: #F1F4F8;
  font-weight: 600;
  font-size: 15px;
}

.ant-picker-header button:hover {
  color: #797B86;
}

.ant-picker-panel .ant-picker-footer {
  border: none;
}

.ant-picker-today-btn {
  color: #F1F4F8;
  font-weight: 600;
}

.ant-picker-header>button {
  color: #F1F4F8;
}

.ant-picker-header>button:hover {
  color: #797B86;
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-light.ant-menu-inline .ant-menu-item,
.ant-menu-light.ant-menu-inline .ant-menu-submenu-title {
  padding: 16px;
  color: #000000 !important;
  padding-right: 30px;
  font-size: 16px;
}

.ant-menu-submenu-title {
  padding-left: 5px !important;
}

.ant-menu-submenu-title svg {
  margin-right: 8px !important;
}

.ant-menu-light .ant-menu-sub {
  background-color: #E0E0E0;
  /* height: 200px;
  overflow-y: scroll; */
  margin: 0 0 20px 0;
}

/* .ant-menu-light .ant-menu-sub::-webkit-scrollbar {
  display: none;
} */
.ant-menu-submenu-popup.ant-menu-light .ant-menu-item-selected,
.ant-menu.ant-menu-light .ant-menu-item-selected {
  background-color: black !important;
  border-right: unset !important;
}

.nested-sider .ant-menu.ant-menu-light .ant-menu-item-selected {
  background-color: #ffff !important;
  z-index: 100;
  border-radius: 7px;
  padding-left: 24px;
  padding-right: 30px;
  border-right: unset !important;
}

.nested-sider .ant-menu.ant-menu-light .ant-menu-item-selected::after {
  border-right: unset !important;
}

.ant-menu-submenu-popup.ant-menu-light .ant-menu-item-selected,
.ant-menu.ant-menu-light .ant-menu-item-selected {
  background-color: var(--purple-color) !important;
  z-index: 100;
  border-radius: 7px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.pl-2 {
  padding-left: 8px;
}

.h-15 {
  height: 60vh;
}

.text-center{
  text-align: center;
}
.pointer{
  cursor: pointer;
}
.text-white{
  color:#fff
}