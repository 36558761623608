.shipment-navbar {
  padding: 50px 36px 0;
  border-bottom: 1px solid #D9D9D9;
}

.shipment-list {
  background-color: #E0E0E0;
  min-height: 100vh;
}

.shipment-list::-webkit-scrollbar {
  display: none;
}

.shipment-wrapper {
  margin-top: 100px;
  padding: 0 10px;
  padding-bottom: 80px;
}

.shipment-wrapper > ul > li > ul {
  margin: 0 !important;
}

.shipment-items {
  display: flex;
  justify-content: space-between;
  border-bottom: 6px solid #ffff !important;
}

.nested-btn {
  position: fixed;
  bottom: 0;
  z-index: 100;
  height: 89px;
  text-align: center;
  transition: all 0.2s;
  background-color: #E0E0E0;
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nested-btn button {
  cursor: pointer;
  margin-bottom: 0;
  background: #262889;
  border: none;
  color: #ffff;
  height: 62px;
  width: 62px;
  border-radius: 50%;
}

.nested-btn button:hover {
  background: #1a1a5a;
  color: #cccccc;
  height: 62px;
  width: 62px;
  border-radius: 50%;
}

.nested-btn button:disabled {
  cursor: not-allowed;
  background: #cccccc;
  color: #ffffff;
}

.delete-scan {
  visibility: hidden;
}

.rfid-level2-space {
  padding-left: 20px;
}

.scan-item:hover .delete-scan {
  visibility: visible;
}

.divider {
  border-bottom: 1px solid #BBBBBB;
}

.submenu-items {
  padding-left: 30px !important;
  padding-right: 8px !important;
}

.selectd-scan {
  background: white !important;
  border-radius: 7px;
  font-size: 16px;
}

.scan-loading {
  position: fixed;
  z-index: 999;
  overflow: visible;
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.scan-loading :before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #262889;
  opacity: 0.98;
  background: -webkit-radial-gradient(#262889 98%);
}

.exception {
  width: 23px;
  height: 20px;
  color: #fff;
  border-radius: 6px;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  background: var(--d-44848, #FF0505);
  box-shadow: 0px 0px 4px 0px rgba(68, 66, 66, 0.41)
}

.scan-details {
  display: flex;
  width: 84px;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

.scan-details-none {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

.rfid-detail {
  display: flex;
  width: 54px;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.rfid-detail-end {
  display: flex;
  width: 54px;
  align-items: center;
  justify-content: end;
  align-content: center;
}

.exception-show {
  width: 7px;
  height: 7px;
  border-radius: 6px;
  background: var(--d-44848, #FF0505);
  box-shadow: 0px 0px 4px 0px rgba(68, 66, 66, 0.41);
  margin: 0 0 16px;
}
.flex-end{
  display: flex;
  justify-content: end;
}

.selected-submenu-dropdown  {
  background-color: white !important;
  border-radius: 8px;
  padding-top: 5px;
}
