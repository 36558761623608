.workSchedulesHeaderContainer {
  align-self: flex-end;
  display: flex;
  align-items: baseline;
  position: relative;
}

.dateScroller {
  margin-left: 250px;
  display: flex;
  align-items: center;
}

.leftIcon,
.rightIcon,
.downIcon {
  background-color: white;
  outline: none;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 10;
}

.rightIcon {
  margin-left: -30px;
}

.customPicker input {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  margin: 0;
}

 .ant-picker-clear{
  display: none;
}

.workScheduleMonth {
  position: absolute;
  left: -15px;
  top: -5px;
}

.workSchduleMonthHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.downIcon {
  font-size: 24px;
}
