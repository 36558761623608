.dropdown-overlay {
  position: absolute;
  right: 33px;
  z-index: 10;
  border: 1px solid #262889;
  margin-top: 0.5rem;
  transform-origin: top right;
  border-radius: 0.375rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffff;
  padding: 14px;
  margin: 0;
  padding-bottom: 10px;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  transition-duration: 300ms;
  min-width: 150px;
  margin: 6px 0;
}

.dropdown-iem {
  height: 40px;
  padding-right: 18px;
  color: #333333;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selected-bold-item {
  height: 40px;
  padding-right: 18px;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-iem svg {
  margin: 9px;
}

.selected-item {
  background-color: #262889;
  color: #fff;
  border-radius: 8px;
}
.error-wrapper{
  border: 1px solid rgba(255, 5, 5, 0.20);
  background: rgba(255, 5, 5, 0.13);
  border-radius: 8px;
  color: #E62E2D;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 14px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}