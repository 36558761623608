.main-header {
    font-size: 16px;
}

.main-header .ant-menu-title-content {
    display: none;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected {
    color: #ffff !important;
    opacity: 0.75 !important;
}

.header-text {
    color: #1D1C1C;
    font-size: 24px;
    font-weight: 700;
}

.fixed-header {
    position: fixed;
    width: calc(100vw - 590px);
    z-index: 10;
}
.invisible {
    height: 95px;
}
.nested-header-text {
    color: #1D1C1C;
    font-size: 15px;
    font-weight: 400;
    padding-left: 6px;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
}

.title-section {
    display: flex;
    align-items: end;
    margin-top: 10px;
    flex-wrap: wrap;
    overflow: hidden;
}

.info-label {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    white-space: nowrap;
    color: #1D1C1C;
}

.info-value {
    color: #1D1C1C;
    font-size: 13px;
    margin-bottom: 5px;
    white-space: nowrap;
}

.icon-container {
    display: flex;
    align-items: center;
    padding: 0 6px;
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    height: 60px;
}
.icon-container:hover {
    opacity: 0.8;
}

.value-container {
    text-align: right;
    padding: 0 12px
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 70px;
}

.header-status-circle {
    width: 8px;
    margin-right: 7px;
    height: 8px;
    border-radius: 50%;
}

.flex-end {
    display: flex;
    align-items: end;
    padding-bottom: 10px;
}

.back-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #D9D9D9;
    width: 17px;
    background: #fff;
    height: 17px;
    border-radius: 50%;
    padding: 0;
    margin-right: 8px;
    cursor: pointer;
}
.items-center{
    align-items: center;
}