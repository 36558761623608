.dropdown-with-name-wrapper {
  position: relative;
}

.dropdown-with-name-wrapper .heading {
  position: absolute;
  z-index: 0;
  padding: 10px;
}

.dropdown-with-name-wrapper {
  display: inline-flex;
  background: #fff;
  font-weight: 500;
  font-size: 15px;
  border-radius: 8px;
  align-items: center;
  width: 100%;
}

.dropdown-with-name-wrapper .heading {
  color: var(--gray);
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.dropdown-with-name-wrapper .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  background: none;
}

.dropdown-with-name-wrapper .heading svg {
  fill: var(--gray);
  margin-right: 5px;
}

.dropdown-with-name-wrapper .ant-select-arrow svg {
  fill: var(--accent-color);
}

.dropdown-with-name-wrapper .ant-select-selection-placeholder {
  font-size: 15px !important;
  padding-left: 20px !important;
  color: #BBBBBB !important;
}
.dropdown-with-name-wrapper .ant-select-selection-search-input {
  padding-left: 20px !important;
}
.ant-select-selection-item svg {
  display: none !important;
}

.dropdown-with-name-wrapper .ant-select-selection-item {
  font-size: 15px !important;
  padding-left: 20px !important;
  color: #000000 !important
}

.check-icon {
  color: #ffff;
  position: absolute !important;
  right: 10 !important;
  top: 28%;
  background: #262889;
}