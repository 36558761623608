.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1D1C1C !important;
}

.logo-img {
    display: block;
    margin: 0 auto;
}

.login-form-wraper {
    height: 100vh !important;
    background: #ffff !important;
    display: flex !important;
}

.login-form {
    margin: auto;
}

.welcome-text {
    text-align: center;
    line-height: 66px;
    margin-bottom: 0;
    font-size: 55px;
    font-weight: 400;
}

.details {
    color: #2B2B2B;
    font-size: 18px;
    opacity: 50%;
    text-align: center;
    padding-bottom: 1rem;
}

.login-btn {
    width: 100%;
    background: #1D1C1C !important;
    color: #ffff !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.forgot-text {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.remember {
    font-size: 16px;
    font-weight: 600;
}

.account-text {
    text-align: center;
    color: #2B2B2B;
}

.justify-between{
    display: flex;
    justify-content: space-between;
}

.justify-start{
    display: flex;
    justify-content: flex-start;
}

.login-card.ant-card {
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
}